import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { FormattedMessage } from "gatsby-plugin-intl"

const Meetings = () => (
  <Layout>
    <Header />
    <section className="page">
      <div className="container">
        <h2>
          <FormattedMessage id="meetings1" />
        </h2>
        <p>
          <FormattedMessage id="meetings2" />
          <AnchorLink to="/#method" className="emailLink">
            <span>
              {" "}
              <FormattedMessage id="meetings3" />{" "}
            </span>
          </AnchorLink>
          <FormattedMessage id="meetings4" />
        </p>
        <p>
          <FormattedMessage id="meetings5" />
        </p>

        <h3>
          <FormattedMessage id="meetings6" />
        </h3>
        <p>
          <FormattedMessage id="meetings7" />
        </p>
        <p>
          <FormattedMessage id="meetings8" />
        </p>
        <p>
          <FormattedMessage id="meetings9" />{" "}
          <AnchorLink to="/#method" className="emailLink">
            <span>
              {" "}
              <FormattedMessage id="meetings10" />{" "}
            </span>
          </AnchorLink>
        </p>
        <p>
          <FormattedMessage id="meetings11" />{" "}
          <a href="mailto: info@zivlovka.cz" className="emailLink">
            <span>
              <FormattedMessage id="meetings12" />
            </span>
          </a>
        </p>
      </div>
    </section>
  </Layout>
)

export default Meetings
